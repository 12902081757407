/** @jsxImportSource @emotion/react */
import * as React from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Post from "../components/Post"

import siteConfig from "../utils/siteConfig.json"

const PostPage = ({ data }: PageProps<GatsbyTypes.MdxQuery>) => {
  return (
    <>
      <Layout>
        <Seo
          type="article"
          pageTitle={data.mdx?.frontmatter?.title}
          description={data.mdx?.frontmatter?.description}
          image={
            data.mdx?.frontmatter?.featured_image &&
            `${siteConfig.siteUrl}/images/${data.mdx?.slug}/${data.mdx?.frontmatter?.featured_image}`
          }
        />
        {data.mdx && <Post post={data.mdx} />}
      </Layout>
    </>
  )
}

export const query = graphql`
  query Mdx($id: String) {
    mdx(id: { eq: $id }) {
      slug
      body
      frontmatter {
        type
        date(formatString: "MMMM D, YYYY")
        title
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData
          }
        }
        description
        featured_image
        category
        tags
      }
    }
  }
`

export default PostPage
