/** @jsxImportSource @emotion/react */
import * as React from "react"
import { css } from "@emotion/react"
import {
  LineShareButton,
  LineIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"

import theme from "../styles/theme"
import siteConfig from "../utils/siteConfig.json"

interface SocialShareProps {
  postTitle?: string
  postSlug?: string
}

const socialShareCss = css`
  text-align: center;
  font-weight: 600;
  p {
    color: ${theme.palette.text.secondary};
  }
  svg {
    circle {
      fill: ${theme.palette.secondary.main};
      opacity: 0.9;
    }
    &:hover {
      circle {
        fill: ${theme.palette.secondary.light};
      }
    }
    margin: 0 20px;
  }
`

const SocialShare = ({ postTitle, postSlug }: SocialShareProps) => {
  const iconSize = 45
  const title = postTitle
    ? `${postTitle} | ${siteConfig.title}`
    : siteConfig.title
  const url = `${siteConfig.siteUrl}/${postSlug}/`

  return (
    <div css={socialShareCss}>
      <p>この記事をシェアする</p>
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon round size={iconSize} />
      </TwitterShareButton>
      <LinkedinShareButton url={url} title={title}>
        <LinkedinIcon round size={iconSize} />
      </LinkedinShareButton>
      <LineShareButton url={url} title={title}>
        <LineIcon round size={iconSize} />
      </LineShareButton>
    </div>
  )
}

export default SocialShare
