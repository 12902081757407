/** @jsxImportSource @emotion/react */
import * as React from "react"
import { css } from "@emotion/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Button from "@mui/material/Button"
import SocialShare from "./SocialShare"
import theme from "../styles/theme"

interface PostProps {
  post: GatsbyTypes.Maybe<
    Pick<GatsbyTypes.Mdx, "slug" | "body"> & {
      readonly frontmatter: GatsbyTypes.Maybe<
        Pick<
          GatsbyTypes.MdxFrontmatter,
          | "type"
          | "date"
          | "title"
          | "description"
          | "featured_image"
          | "category"
          | "tags"
        > & {
          readonly embeddedImagesLocal: GatsbyTypes.Maybe<
            ReadonlyArray<
              GatsbyTypes.Maybe<{
                readonly childImageSharp: GatsbyTypes.Maybe<
                  Pick<GatsbyTypes.ImageSharp, "gatsbyImageData">
                >
              }>
            >
          >
        }
      >
    }
  >
}

const postCss = css`
  margin: auto;
  padding: auto;
  min-width: 275;
  color: ${theme.palette.text.primary};
`

const postDateCss = css`
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 300;
  color: ${theme.palette.text.secondary};
`

const postTitleCss = css`
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  color: ${theme.palette.text.primary};
  opacity: 0.84;
`

const tagCss = css`
  margin-top: 12px;
  padding-left: 0px;
  margin-bottom: 40px;
  button:disabled {
    font-family: sans-serif;
    text-transform: none;
    margin-right: 6px;
    color: ${theme.palette.text.primary};
    border-color: ${theme.palette.text.primary};
    background-color: ${theme.palette.primary.main};
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 11px;
    padding-left: 11px;
    box-shadow: none;
  }
`

const postBodyCss = css`
  h2 {
    margin-top: 60px;
    margin-bottom: 0px;
    font-size: 28px;
    font-weight: 600;
    opacity: 0.84;
    border-bottom: 1px solid ${theme.palette.secondary.light};
  }
  h3 {
    margin-top: 30px;
    margin-bottom: -15px;
    font-size: 21px;
    font-weight: 600;
    opacity: 0.84;
    code {
      font-family: system-ui;
      color: ${theme.palette.text.primary};
    }
  }
  h4 {
    margin-bottom: -18px;
    font-size: 17px;
    color: ${theme.palette.text.primary};
    opacity: 0.9;
    code {
      font-family: system-ui;
    }
  }
  div.custom-block.digression {
    border-left: 5px solid ${theme.palette.primary.dark};
    margin: 25px 2px;
    padding: 15px 40px;
    p {
      font-size: 15px;
      margin: 0.3em 0px;
      color: ${theme.palette.text.disabled};
    }
    span.katex {
      color: ${theme.palette.text.disabled};
    }
    a {
      color: ${theme.palette.text.disabled};
      &:hover {
        color: ${theme.palette.text.disabled};
        opacity: 0.7;
      }
    }
  }
  p {
    color: ${theme.palette.text.secondary};
    font-size: 16px;
    font-weight: 300;
    line-height: 1.9em;
    margin: 1.6em 0;
    strong {
    }
  }
  span.katex {
    color: ${theme.palette.text.secondary};
    span.katex-html span.tag {
      position: static;
      float: right;
    }
  }
  a {
    color: ${theme.palette.text.secondary};
    &:hover {
      color: ${theme.palette.text.disabled};
    }
  }
  li {
    color: ${theme.palette.text.secondary};
    font-size: 16px;
    font-weight: 300;
    line-height: 1.7em;
    margin: 0.4em 0;
  }
  div.gatsby-highlight {
    margin: 30px 0;
    pre[class*="language-"] {
      padding: 30px 30px;
      background-color: #2f3d3f;
    }
    code[class*="language-"] {
      font-size: 14px;
      font-weight: 300;
      font-family: Menlo, Monaco, Consolas, "Andale Mono", "Ubuntu Mono",
        "Courier New", monospace;
    }
  }
  code {
    font-family: system-ui;
  }
  .image {
    text-align: center;
  }
  div.table-of-contents {
    border: solid 1px ${theme.palette.secondary.light};
    li {
      margin: 0;
    }
    @media (max-width: 600px) {
      padding-left: 30px;
      padding-right: 40px;
      padding-top: 20px;
      padding-bottom: 8px;
    }
    @media (max-width: 450px) {
      padding-left: 25px;
      padding-right: 35px;
      padding-top: 20px;
      padding-bottom: 5px;
    }
    margin-top: 40px;
    margin-bottom: 20px;
    padding-left: 35px;
    padding-right: 45px;
    padding-top: 25px;
    padding-bottom: 10px;
    ::before {
      content: "目次";
      font-size: 19px;
      margin: 10px;
      @media (max-width: 450px) {
        font-size: 17px;
      }
      font-weight: 600;
      opacity: 0.84;
    }
    p {
      margin: 0;
    }
    ul {
      padding-left: 30px;
    }
    a {
      scroll-behavior: smooth;
      font-size: 16px;
      @media (max-width: 450px) {
        font-size: 14px;
      }
      color: ${theme.palette.text.secondary};
      text-decoration: none;
      &:hover {
        color: ${theme.palette.text.primary};
        background-color: ${theme.palette.primary.dark};
      }
    }
  }
`

const scrollCss = css`
  text-align: right;
  font-size: 14px;
  color: ${theme.palette.text.secondary};
  &:hover {
    color: ${theme.palette.text.disabled};
    cursor: pointer;
  }
`

const Post = ({ post }: PostProps) => {
  return (
    <div css={postCss}>
      <p css={postDateCss}>{post.frontmatter?.date}</p>
      <h1 css={postTitleCss}>{post.frontmatter?.title}</h1>
      <div css={tagCss}>
        {post.frontmatter?.tags &&
          post.frontmatter?.tags.map(tag => (
            <Button disabled variant="outlined" key={tag}>
              {tag}
            </Button>
          ))}
      </div>
      {post.body && (
        <div css={postBodyCss}>
          <MDXRenderer localImages={post.frontmatter?.embeddedImagesLocal}>
            {post.body}
          </MDXRenderer>
        </div>
      )}
      <div
        css={scrollCss}
        onClick={() => {
          window.scrollTo({
            top: 0,
          })
        }}
      >
        Top へ戻る
      </div>
      <SocialShare postTitle={post.frontmatter?.title} postSlug={post.slug} />
    </div>
  )
}

export default Post
